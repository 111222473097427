/* eslint-disable no-redeclare */
export function formatDate(dateValue, formatType="month_name") {
    var date=new Date(dateValue)
    var monthNames = [
        "Jan", "Feb", "Mar",
        "Apr", "May", "Jun", "Jul",
        "Aug", "Sep", "Okt",
        "Nov", "Dec"
    ];

    var day = date.getDate();
    var monthIndex = date.getMonth();
    var year = date.getFullYear();
    var outputDate=""
    if(Number.isNaN(monthIndex)==true){
        return "-"
    }
    switch(formatType){
        default:
            outputDate= day + ' ' + monthNames[monthIndex] + ' ' + year;
        break;
        case "iso":
            var isoDate= new Date(year, monthIndex, day);
            outputDate= isoDate.toISOString().slice(0,10);
        break;
        case 'datetime':
            var hours = date.getHours();
            hours=(hours<10)?'0'+hours:hours
            var minutes = date.getMinutes();
            var seconds = date.getSeconds();
            monthIndex=(monthIndex+1)
            monthIndex=(monthIndex<10)?"0"+monthIndex:monthIndex
            day=(day<10)?"0"+day:day
            minutes=(minutes<10)?"0"+minutes:minutes
            seconds=(seconds<10)?"0"+seconds:seconds
            outputDate= day + '/' +monthIndex + '/' + year+" "+hours+":"+minutes+":"+seconds;
        break;
    }
    return outputDate;
}

/**
 * format tanggal
 */
export function getCurrentDate(formatValue) {
    var currentDate = new Date();
    if(formatValue && formatValue!=''){
        currentDate = new Date().toJSON().slice(0,10).replace(/-/g,'/');
        switch(formatValue){
            case 'm/d/y':
                currentDate=new Date().toISOString().slice(0,10)
            break;
            case 'first_day':
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                var firstDay = new Date(y, m, 1+1);
                currentDate=firstDay.toISOString().slice(0,10)
            break;
            case 'last_day':
                var date = new Date(), y = date.getFullYear(), m = date.getMonth();
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
                currentDate=lastDay.toISOString().slice(0,10)
            break;
            default:
                currentDate = new Date().toJSON().slice(0,10).replace(/-/g,'/');
            break;
        }
    } 
    return currentDate
}

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
    return null
    }
    const format = cFormat || '{h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
        }
        const formatObj = {
            y: date.getFullYear(),
            m: date.getMonth() + 1,
            d: date.getDate(),
            h: date.getHours(),
            i: date.getMinutes(),
            s: date.getSeconds(),
            a: date.getDay()
        }
        const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

export function formatTime(time, option) {
    if (('' + time).length === 10) {
      time = parseInt(time) * 1000
    } else {
        time = +time
    }
    const d = new Date(time)
    const now = Date.now()

    const diff = (now - d) / 1000
    
    if (diff < 30) {
        return '刚刚'
    } else if (diff < 3600) {
    // less 1 hour
        return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
        return '1天前'
    }
    if (option) {
        return parseTime(time, option)
    } else {
        return (
            d.getMonth() +
            1 +
            '月' +
            d.getDate() +
            '日' +
            d.getHours() +
            '时' +
            d.getMinutes() +
            '分'
        )
    }
}

export function formatPrice(value) {
    let val = (value/1).toFixed(2).replace('.', ',')
    // let val = (value/1).toFixed(0).replace('.', ',')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export function formatNumber(value) {
    // let val = (value/1).toFixed(0).replace('.', ',')
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
