require("./lib");

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./lib/vuetify";
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
const moment = require('moment')
 
Vue.use(require('vue-moment'), {
    moment
})
Vue.config.productionTip = false;

(async () => {
  await store.dispatch("get_user");

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
})();
