import Vue from 'vue'
import VueRouter from 'vue-router'
import Views from '../views'
// import Home from '../views/Home'
// import Unauth from '../views/unauth'
import store from '../store'

Vue.use(VueRouter)

let routes = {
  auth: [
    // { path: '/dashboard-new', name: 'dashboard-new', component: Views.Dashboard },    
    { path: '/dashboard', name: 'dashboard', component: Views.User.Dashboard },
    { path: '/deposit', name: 'deposit', component: Views.User.Deposit },
    { path: '/balance-deposit', name: 'balance-deposit', component: Views.User.BalanceDeposit },
    { path: '/balance-deposit-detail/:id', name: 'balance-deposit-detail', component: Views.User.BalanceDepositDetail },

    // ETRANSFER
    { path: '/history-money', name: 'history-money', component: Views.Transfer.History },

    { path: '/disbursement', name: 'disbursement', component: Views.Transfer.SingleDisbursement },
    // { path: '/disbursement', name: 'disbursement', component: Views.Transfer.Disbursement },
    { path: '/disbursement-detail/:id', name: 'disbursement-detail', component: Views.Transfer.DisbursementDetail },
    { path: '/disbursement-detail-need-validation/:id', name: 'disbursement-detail-need-validation', component: Views.Transfer.DisbursementDetailNeedValidation },
    { path: '/disbursement-detail-need-approval/:id', name: 'disbursement-detail-need-approval', component: Views.Transfer.DisbursementDetailNeedApproval },
    { path: '/disbursement-detail-approved/:id', name: 'disbursement-detail-approved', component: Views.Transfer.DisbursementDetailApproved },
    { path: '/disbursement-rilis/:id', name: 'disbursement-rilis', component: Views.Transfer.DisbursementRilis },
    { path: '/disbursement-create', name: 'disbursement-create', component: Views.Transfer.DisbursementCreate },
    { path: '/disbursement-edit/:id', name: 'disbursement-edit', component: Views.Transfer.DisbursementEdit },

    { path: '/bulk-disbursement', name: 'bulk-disbursement', component: Views.Transfer.Bulk },
    { path: '/bulk-disbursement-create', name: 'bulk-disbursement-create', component: Views.Transfer.BulkDisbursementCreate },
    { path: '/bulk-disbursement-edit/:id', name: 'bulk-disbursement-edit', component: Views.Transfer.BulkDisbursementEdit },
    { path: '/bulk-create', name: 'bulk-create', component: Views.Transfer.BulkCreate },
    { path: '/bulk-detail-all/:id', name: 'bulk-detail-all', component: Views.Transfer.BulkDetailAll },
    { path: '/bulk-detail/:id', name: 'bulk-detail', component: Views.Transfer.BulkDetail },
    { path: '/bulk-detail-approval/:id', name: 'bulk-detail-approval', component: Views.Transfer.BulkDetailNeed },
    { path: '/bulk-detail-approved/:id', name: 'bulk-detail-approved', component: Views.Transfer.BulkDetailApproved },
    { path: '/bulk-rilis-disbursement/:id', name: 'bulk-rilis-disbursement', component: Views.Transfer.BulkRilisDisbursement },
    
    { path: '/schedule-disbursement', name: 'schedule-disbursement', component: Views.Transfer.Schedule },
    { path: '/schedule-detail/:id', name: 'schedule-detail', component: Views.Transfer.ScheduleDetail },
    { path: '/schedule-create', name: 'schedule-create', component: Views.Transfer.ScheduleCreate },
    { path: '/request-transfer', name: 'request-transfer', component: Views.Transfer.ReceivePayment },
    { path: '/create-request-transfer', name: 'create-request-transfer', component: Views.Transfer.ReceiveCreate },
    { path: '/request-transfer-detail/:id', name: 'request-transfer-detail', component: Views.Transfer.ReceiveDetail },
    { path: '/incoming-transfer', name: 'incoming-transfer', component: Views.Transfer.ReceiveIncoming },
    { path: '/invoice', name: 'invoice', component: Views.Transfer.ReceiveInvoice },
    { path: '/create-invoice', name: 'create-invoice', component: Views.Transfer.ReceiveInvoiceCreate },
    { path: '/invoice-detail/:id', name: 'invoice-detail', component: Views.Transfer.ReceiveInvoiceDetail },
    { path: '/email-invoice', name: 'email-invoice', component: Views.Transfer.receiveEmailInvoice },

    // ECERTFICATE
    { path: '/certificate', name: 'list-certificate', component: Views.Certif.CertifList },
    { path: '/certificate/create', name: 'create-certificate', component: Views.Certif.CertifCreate },
    { path: '/certificate/:id', name: 'certificate-detail', component: Views.Certif.CertifDetail },
    { path: '/certificate/create-receive/:id', name: 'create-certificate-receive', component: Views.Certif.CertifCreateReceive },
    { path: '/certificate/certificate-receive/edit/:id', name: 'edit-create-certificate-receive', component: Views.Certif.CertifEditCreateReceive },
    { path: '/template', name: 'list-template', component: Views.Certif.TempList },
    { path: '/template/detail/:id', name: 'template-detail', component: Views.Certif.TempDetail },
    { path: '/template/create', name: 'create-template', component: Views.Certif.TempCreate },
    { path: '/template/edit/:id', name: 'edit-template', component: Views.Certif.TempEdit },
    { path: '/template/request-desain', name: 'request-desain', component: Views.Certif.TempReq },

  ],
  guest: [
    { path: '/', name: 'login', component: Views.Auth.Login },
    { path: '/registration', name: 'registration', component: Views.Auth.Registration },
    { path: '/reset-password/:id', name: 'reset-password', component: Views.Auth.ResetPassword },
    { path: '/send-reset', name: 'send-reset', component: Views.Auth.SendReset },
    { path: '/success-email/:id', name: 'success-email', component: Views.Auth.EmailVerifikasi },
    { path: '/success-reset', name: 'reset-success', component: Views.Auth.SuccessReset },
  ],
  default: [
    { path: '*', name: '404 Not Found', component: Views.Errors.NotFound },
  ]
}

routes.auth.forEach(route => { route.meta = { auth: true } });
routes.guest.forEach(route => { route.meta = { guest: true } });
routes = [
  ...routes.auth,
  ...routes.guest,
  ...routes.default,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == '/' && store.getters.isLoggedIn) {
    return next('/dashboard')
  }
  if (to.meta.auth && !store.getters.isLoggedIn) {
    sessionStorage.setItem('momofin_auth', location.pathname)
    return next('/')
  }
  if (to.meta.guest && store.getters.isLoggedIn) {
    return next('/dashboard')
  }
  return next()
})

export default router
