<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0"> 
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Transaction History</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="row">
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">FUND</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(balance) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL OUTGOING</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(total_outgoing) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL INCOMING</p>
                <p class="fs-18 text-black">Rp {{ formatPrice(total_incoming) }}</p>
              </v-card-text>
            </v-card>
          </div>
          <div class="col-md-3">
            <v-card rounded="lg" elevation="1">
              <v-card-text class="py-5">
                <p class="text-semu">TOTAL TRANSACTION</p>
                <p class="fs-18 text-black">{{ total_transaction }}</p>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="table my-3">          
          <v-card>
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="col-md-3 px-0">
                  <v-select
                    v-model="columnFilter.status"
                    :items="ex"
                    :item-value="`value`"
                    item-text="label"
                    placeholder="Select Status"
                    class="ma-1"
                    @change="getListTransaction(columnFilter.ex)"
                  ></v-select>
                </div>
                <div class="col-md-3 px-0">
                  <v-select
                    v-model="columnFilter.type"
                    :items="transaction_type"
                    :item-value="`value`"
                    item-text="label"
                    placeholder="Select Status"
                    @change="getListTransaction(columnFilter.type)"
                    class="ma-1"
                  ></v-select>
                </div>
                <div class="col-md-3 px-0">                  
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    elevation="0"
                  >
                    <v-date-picker
                      ref="picker"
                      v-model="columnFilter.date"
                      min="1950-01-01"
                      outlined
                      required
                      range
                      @change="getListTransaction(columnFilter.date)"
                    ></v-date-picker>
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeText"
                        placeholder="Start date - End date"                    
                        append-icon="ri-calendar-line"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-1"
                      ></v-text-field>                   
                    </template>
                  </v-menu>
                </div>
                <!-- <div class="col-md-2 px-0">                  
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    class="ma-1"
                  ></v-text-field>
                </div> -->
                <div class="col-md-1 px-0">
                  <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true" style="min-height: 48px;">
                    <v-icon class="mr-2">ri-download-cloud-fill </v-icon> Export
                  </v-btn>
                </div>
              </div>
            </v-card-title>

            <v-data-table
              :headers="headers"
              :items="listTransaction"
              :search="search"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >

              <template v-slot:[`item.createdAt`]="{ item }">
                {{ formatDate(item.createdAt) }}
              </template>

              <template v-slot:[`item.updatedAt`]="{ item }">
                {{ parseTime(item.updatedAt) }}
              </template>

              <template v-slot:[`item.remark`]="{ item }">
                {{ item.remark || "-" }}
              </template>

              <template v-slot:[`item.payment_channel`]="{ item }">
                {{ item.payment_channel || "-" }}
              </template>

              <template v-slot:[`item.amount`]="{ item }" >
                <template v-if="item.type === 'out'">
                  <span class="text-red">-</span>
                </template>
                <template v-if="item.type === 'in'">
                  <span class="text-green">+</span>
                </template>
                {{ formatPrice(item.amount) }}
              </template>

              <!-- <template v-slot:item.status="{ item }"> -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status === 'pending'"
                  color="#E91E63"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'processing'"
                  color="#3949AB"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'failed'"
                  color="#E53935"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'canceled'"
                  color="#D81B60"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'refund'"
                  color="#F0AB0B"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else
                  :color="item.status == 'completed' ? 'success' : '#ADADAD'"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </div>        
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="dialog=false"
        >
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5">
          Export Transaction History Report
        </v-card-title>

        <v-card-text class="my-3">
          <p class="mb-0">Select File Type</p>
          <v-select
            :items="ex"
            placeholder="Select Type"
            class="ma-1"
          ></v-select>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { parseTime, formatDate, formatPrice } from "@/lib/formatDate";
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    dialog:false,
    menu:false,
    isLoading:false,
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '/history-money',
      },
      {
        text: 'Transaction History',
        disabled: true,
        href: '#',
      }
    ],
    ex: [],
    transaction_type: [],
    search: '',
    headers: [
      {
        text: 'TRANSACTION ID',
        align: 'start',
        sortable: false,
        value: 'transaction_code',
      },
      { text: 'DATE', value: 'createdAt' },
      { text: 'TIME', value: 'updatedAt' },
      { text: 'DESCRIPTION', value: 'description' },
      { text: 'REMARK', value: 'remark' },
      { text: 'CHANNEL', value: 'payment_channel' },
      { text: 'AMOUNT (RP)', value: 'amount', align: 'right' },
      { text: 'STATUS', value: 'status' },
    ],
    // headers: [
    //   {
    //     text: 'TRANSACTION ID',
    //     align: 'start',
    //     sortable: false,
    //     value: 'name',
    //   },
    //   { text: 'DATE', value: 'calories' },
    //   { text: 'TIME', value: 'fat' },
    //   { text: 'DESCRIPTION', value: 'carbs' },
    //   { text: 'REMARK', value: 'protein' },
    //   { text: 'CHANNEL', value: 'iron' },
    // ],
    // desserts: [
    //   {name:"Frozen Yogurt",calories:159,fat:6,carbs:24,protein:4,iron:"1%"}, // 1
    //   {name:"Ice cream sandwich",calories:237,fat:9,carbs:37,protein:4.3,iron:"1%"}, // 2
    //   {name:"Eclair",calories:262,fat:16,carbs:23,protein:6,iron:"7%"}, // 3
    //   {name:"Cupcake",calories:305,fat:3.7,carbs:67,protein:4.3,iron:"8%"}, // 4
    //   {name:"Gingerbread",calories:356,fat:16,carbs:49,protein:3.9,iron:"16%"}, // 5
    //   {name:"Jelly bean",calories:375,fat:0,carbs:94,protein:0,iron:"0%"}, // 6
    //   {name:"Lollipop",calories:392,fat:.2,carbs:98,protein:0,iron:"2%"}, // 7
    //   {name:"Honeycomb",calories:408,fat:3.2,carbs:87,protein:6.5,iron:"45%"}, // 8
    //   {name:"Donut",calories:452,fat:25,carbs:51,protein:4.9,iron:"22%"}, // 9
    //   {name:"KitKat",calories:518,fat:26,carbs:65,protein:7,iron:"6%"}, // 10
    //   {name:"Jelly bean",calories:375,fat:0,carbs:94,protein:0,iron:"0%"}, // 11
    //   {name:"Lollipop",calories:392,fat:.2,carbs:98,protein:0,iron:"2%"}, // 12
    //   {name:"Ice cream sandwich",calories:237,fat:9,carbs:37,protein:4.3,iron:"1%"}, // 13
    //   {name:"Eclair",calories:262,fat:16,carbs:23,protein:6,iron:"7%"}, // 14
    //   {name:"Cupcake",calories:305,fat:3.7,carbs:67,protein:4.3,iron:"8%"}, // 15
    //   {name:"Ice cream sandwich",calories:237,fat:9,carbs:37,protein:4.3,iron:"1%"}, // 16
    //   {name:"Eclair",calories:262,fat:16,carbs:23,protein:6,iron:"7%"}, // 17
    //   {name:"Cupcake",calories:305,fat:3.7,carbs:67,protein:4.3,iron:"8%"}, // 18
    //   {name:"qwe",calories:305,fat:3.7,carbs:67,protein:4.3,iron:"8%"}, // 19
    //   {name:"asd",calories:305,fat:3.7,carbs:67,protein:4.3,iron:"8%"}, // 20
    //   {name:"zxc",calories:305,fat:3.7,carbs:67,protein:4.3,iron:"8%"}, // 21
    // ],
    dates:[],
    columnFilter: {
      status: [],
      type: [],
      date: [],
    },
    listTransaction: [],
  }),
  mounted() {
    this.getListTransaction();
    this.loadBalanace();
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
    ...mapGetters({
      balance: 'getBalance',
      total_outgoing: 'getTotOutgoing',
      total_incoming: 'getTotIncoming',
      total_transaction: 'getTotTransaction',
    }),
  },
  methods: {
    loadBalanace() {
      this.$store.dispatch("getBalanceTransaction");
    },
    
    getListTransaction() {
      this.isLoading = true;
      this.$store.dispatch("getListTransaction", this.columnFilter)
      .then(response => {
        const { data } = response;
        this.listTransaction = data.result.transaction;
        this.ex = data.result.filter.status;
        this.transaction_type = data.result.filter.transaction_type;
        // console.log('hasil', this.listTransaction);
        this.isLoading = false;
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    formatDate(value) {
      return formatDate(value);
    },
    formatPrice(value) {
      return formatPrice(value);
    },
    parseTime(value) {
      return parseTime(value);
    },
  },
};
</script>

<style>
.text-red {
  color: #F44336;
}
.text-green {
  color: #4CAF50;
}
</style>