<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Disbursement</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="table my-3">          
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="col-md-6">
                  <p class="fs-16 text-black font-weight-medium">List of Transaction</p>
                </div>
                <div class="col-md-6 text-right">
                  <v-btn color="primary" class="white--text ma-1" :to="{ name: 'schedule-create'}">
                    Create Scheduled Disbursement
                  </v-btn>
                </div>
              </div>
              <div class="col-md-12 px-0">
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-6 px-0">
                  <div class="d-flex">
                    <v-select
                      v-model="columnFilter.status"
                      :items="ex"
                      :item-value="`value`"
                      item-text="label"
                      placeholder="Select Status"
                      @change="getListScheduleDisbursment(columnFilter.ex)"
                      class="ma-1"
                    ></v-select>
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      elevation="0"
                    >
                      <v-date-picker
                        ref="picker"
                        v-model="columnFilter.date"
                        min="1950-01-01"
                        outlined
                        required
                        range
                        @change="getListScheduleDisbursment(columnFilter.date)"
                      ></v-date-picker>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateRangeText"
                          placeholder="Start date - End date"                    
                          append-icon="ri-calendar-line"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-1"
                        ></v-text-field>                   
                      </template>
                    </v-menu>
                  </div>
                </div>
                <div class="col-md-6 px-0 align-end text-end">
                  <div class="d-flex">                  
                    <v-text-field
                      outlined
                      v-model="search"
                      append-icon="$search"
                      class="mt-1 ms-2 mx-width-85"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>
                    <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true" style="min-height: 48px;">
                      <v-icon class="mr-2">ri-download-cloud-fill </v-icon> Export
                    </v-btn>
                  </div>                  
                </div>
              </div>
            </v-card-title>

            <div v-if="!listTransaction" class="col-md-6 mx-auto my-2">
              <img src="@/assets/images/empty.png"> 
              <p class="font-weight-bold text-black mb-2">No Disbursement transaction found.</p>
              <p class="text-semu">All transaction of your Momofin account will appear here.</p>
              <v-btn color="primary" class="ma-1" outlined>
                Create Schedule Disbursement
              </v-btn>
            </div>

            <v-data-table
              v-else
              :headers="headers"
              :items="listTransaction"
              :search="search"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.approved_at`]="{ item }">
                {{ parseTime(item.approved_at) }}
              </template>

              <template v-slot:[`item.total_amount`]="{ item }">
                {{ formatPrice(item.total_amount) }}
              </template>

              <!-- <template v-slot:item.date="{ item }"> -->
              <template v-slot:[`item.created_at`]="{ item }">
                <router-link
                  class=""
                  :to="{ name: 'schedule-detail', params: { id: item.id } }"
                >
                {{ formatDate(item.created_at) }}
                </router-link>
              </template>

              <!-- <template v-slot:item.status="{ item }"> -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status === 'canceled'"
                  color="#8B8E94"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === null"
                  color="#455A64"
                  outlined
                >
                  -
                </v-chip>
                <v-chip
                  v-else
                  :color="item.status == 'approved' ? 'success' : '#F0AB0B'"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </div>        
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="dialog=false"
        >
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5">
          Export Transaction History Report
        </v-card-title>

        <v-card-text class="my-3">
          <p class="mb-0">Select File Type</p>
          <v-select
            :items="ex"
            placeholder="Select Type"
            class="ma-1"
          ></v-select>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { parseTime, formatDate, formatPrice } from "@/lib/formatDate";

export default {
    data: () => ({
      dialog:false,
      menu:false,
      items: [
        {
          text: 'E-Transfer',
          disabled: false,
          href: '#',
        },
        {
          text: 'Send Money',
          disabled: false,
          href: '#',
        },
        {
          text: 'Schedule Disbursement',
          disabled: true,
          href: '#',
        }
      ],
      ex: [],
      search: '',
      headers: [
        { text: 'DATE CREATED', value: 'created_at' },
        { text: 'TIME', value: 'approved_at' },
        { text: 'DESCRIPTION', value: 'description' },
        { text: 'CUSTOMER/CUSTOMER GROUP', value: 'creator_user_name' },
        { text: 'AMOUNT', value: 'total_amount', align: 'right' },
        { text: 'STATUS', value: 'status' },
      ],
      columnFilter: {
        status: [],
        date: [],
      },
      isLoading:false,
      dates:[],
      listTransaction: []
    }),
  async mounted() {
    await this.getListScheduleDisbursment();
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },
  methods: {
    getListScheduleDisbursment() {
      this.isLoading = true;
      this.$store.dispatch("getListScheduleDisbursment", this.columnFilter)
      .then(response => {
        const { data } = response;
        this.listTransaction = data.result.schedule_disbursement;
        this.ex = data.result.filter.status;
        this.isLoading = false;
        // console.log('hasil', this.listTransaction);
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    formatDate(value) {
      return formatDate(value);
    },
    formatPrice(value) {
      return formatPrice(value);
    },
    parseTime(value) {
      return parseTime(value);
    },
  },
};
</script>

<style>
</style>