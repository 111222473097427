<template>
  <transition name="fade">
    <v-app v-if="loaded">
      <!-- Sidenav / Navigation drawer -->
      <v-navigation-drawer
        id="navigasi"
        fixed
        floating
        v-model="drawer"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :temporary="!$vuetify.breakpoint.mdAndUp"
        class="elevation-1 bg-navy"
      >
        <img
          src="@/assets/images/logo.png"
          alt="Avatar"
          class="ma-3 ml-5"
          style="width: 150px;"
        />
        <!-- <v-spacer class="mt-16"></v-spacer> -->

        <v-list flat>
          <template v-for="item in items">
            <v-list-item
              active-class="menu-active"
              class="transparent fs-14"
              :key="item.title"
              :to="item.link"
              @click="pageChanged(0, item)"
            >
              <!-- active-class="menu-active" -->
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- <v-list-group
              v-else-if="!item.disabled"
              :key="item.title"
              :prepend-icon="item.icon"
              active-class="menu-active"
            >
              <template slot="activator">
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </template>

              <v-list-item
                class="background"
                color="primary"
                v-for="item in item.items"
                :key="item.title"
                :to="item.link"
              >
                <v-list-item-icon>
                  <v-icon v-text="''"></v-icon>
                </v-list-item-icon>

                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </v-list-group> -->
          </template>
          <!-- <v-list-item
              class="py-1 pl-6"
              active-class="menu-active"
              to="/dashboard"
            >
              <v-list-item-icon class="mr-4">
                <v-icon>$dashboard</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="text-subtitle-1 color--text">Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item to="">
              <v-list-group>
                <template v-slot:activator class="pl-2">
                  <v-list-item-icon class="mr-4">
                    <v-icon class="color--text"> $epot </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1 color--text"> Tes EPOT </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item to="/exam/greeting">
                  <v-list-item-icon class="mr-4">
                    
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2 color--text"> Greeting </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item to="/epot">
                  <v-list-item-icon class="mr-4">
                    
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-2 color--text"> Kelola Tes </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list-item> -->
        </v-list>
        <v-divider style="border-color:#0C3664;"></v-divider>
        <v-list flat>
          <p
            class="mb-1 pt-2 pl-6 font-weight-medium fs-12 text-blue-menu"
            style="letter-spacing: 0.25em;"
          >
            E-TRANSFER
          </p>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-icon>
                <img src="@/assets/images/withdraw 1.svg" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Receive Money
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in receive">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <v-list-group :value="false">
            <template v-slot:activator>
              <v-list-item-icon>
                <img src="@/assets/images/money-bag 1.svg" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Send Money
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-for="item in trans">
              <v-list-item
                active-class="menu-active"
                class="transparent fs-14"
                :key="item.title"
                :to="item.link"
                @click="pageChanged(0, item)"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-1">
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-group>
          <template v-for="item in history">
            <v-list-item
              active-class="menu-active"
              class="transparent text-semi fs-14"
              :key="item.title"
              :to="item.link"
              @click="pageChanged(0, item)"
            >
              <!-- active-class="menu-active" -->
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
        <v-divider style="border-color:#0C3664;"></v-divider>
        <v-list flat>
          <p
            class="mb-1 pt-2 pl-6 font-weight-medium fs-12 text-blue-menu"
            style="letter-spacing: 0.25em;"
          >
            E-CERTIFICATE
          </p>
          <template>
            <v-list-item
              active-class="menu-active"
              exact-active-class="menu-active"
              class="transparent fs-14"
              :to="{name: 'list-certificate'}"
            >
              <v-list-item-icon>
                <img src="@/assets/images/icon-certif.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  Certificate
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              exact-active-class="menu-active"
              active-class="menu-active"
              class="transparent fs-14"
              :to="{name: 'list-template'}"
            >
              <v-list-item-icon>
                <img src="@/assets/images/icon-temp.svg" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  Template
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-navigation-drawer>

      <!-- Topnav / App bar -->
      <v-main
        id="tool"
        class="background"
        :style="{marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0}"
      >
        <v-app-bar
          id="tool"
          color="background bg-navy"
          :style="{marginLeft: $vuetify.breakpoint.mdAndUp ? '256px' : 0}"
          flat
          fixed
          class="bg-navy"
        >
          <v-app-bar-nav-icon
            v-if="!$vuetify.breakpoint.mdAndUp"
            class="ma-2"
            @click="drawer = true"
          ></v-app-bar-nav-icon>
          <v-spacer v-if="!$vuetify.breakpoint.mdAndUp"></v-spacer>

          <img v-if="!$vuetify.breakpoint.mdAndUp" src="@/assets/images/logo.png" alt="Avatar" class="ma-3 ml-5" style="width: 150px;" />

          <!-- <v-toolbar-title>
            <h3 class="font-weight-regular">{{ pageTitle }}</h3>
          </v-toolbar-title> -->
          <v-text-field
            outlined
            append-icon="$search"
            class="pt-0 mt-0 mr-4 mx-width-85 d-none d-lg-block"
            placeholder="Search"
            solo
            flat
          ></v-text-field>

          <v-spacer></v-spacer>

          <div class="d-none d-lg-block">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  color="white"
                  class="mx-2 my-3"
                  small
                  icon
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>ri-questionnaire-line</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item class="fs-1">
                  <v-list-item-title
                    v-text="'Tidak ada notifikasi'"
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider
              vertical
              class="my-auto"
              style="height: 40px; min-height: auto"
            ></v-divider>
          </div>
          <div class="d-none d-lg-block">
            <v-menu offset-y>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  color="white"
                  class="mx-2 my-3"
                  small
                  icon
                  elevation="1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>$notification</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item class="fs-1">
                  <v-list-item-title
                    v-text="'Tidak ada notifikasi'"
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider
              vertical
              class="my-auto"
              style="height: 40px; min-height: auto"
            ></v-divider>
          </div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-6 rounded-pill d-none d-lg-block" v-ripple v-bind="attrs" v-on="on">
                <v-avatar class="elevation-1 white mr-2" size="40px">
                  <img src="@/assets/images/no-pict.png" alt="Avatar" />
                </v-avatar>
                <span class="font-weight-medium">{{ user.fullname }}</span>
                <v-icon class="mx-2" small>$dropdown</v-icon>
              </div>
            </template>

            <v-list dense>
              <!-- <v-list-item link to="/profil">
                <v-list-item-title v-text="'Profil'"></v-list-item-title>
              </v-list-item>
              <v-list-item link to="/update-password">
                <v-list-item-title
                  v-text="'Update Password'"
                ></v-list-item-title>
              </v-list-item> -->
              <v-list-item link @click="logout" class="fs-1">
                <v-list-item-title v-text="'Logout'"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu offset-y >
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-4 rounded-pill d-lg-none d-md-block" v-ripple v-bind="attrs" v-on="on">
                <v-avatar class="elevation-1 white mr-2" size="40px">
                  <img src="@/assets/images/no-pict.png" alt="Avatar" />
                </v-avatar>
                <span class="font-weight-medium">{{ user.fullname }}</span>
                <v-icon class="mx-2" small>$dropdown</v-icon>
              </div>
            </template>

            <v-list dense>
              <v-list-item link to="/dashboard">
                <v-list-item-title v-text="'Notifikasi'"></v-list-item-title>
              </v-list-item>
              <!-- <v-list-item link to="/dashboard">
                <v-list-item-title
                  v-text="''"
                ></v-list-item-title>
              </v-list-item> -->
              <v-list-item link @click="logout">
                <v-list-item-title v-text="'Logout'"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>

        <v-container fluid style="margin-top: 64px">
          <div class="content-wrapper mx-2">
            <!-- <v-breadcrumbs class="px-0 pt-0" :items="breadcrumbs">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item>
                  <span v-if="item.disabled">
                    {{ item.text }}
                  </span>
                  <router-link class="text-decoration-none" v-else :to="item.href">
                    {{ item.text }}
                  </router-link>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs> -->

            <transition name="fade-up">
              <router-view
                v-if="loaded"
                @page-changed="pageChanged"
              ></router-view>
            </transition>
          </div>
        </v-container>
      </v-main>
    </v-app>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";
import menu from "./menu";
import transfer from "./transfer";
import contract from "./contract";
import setting from "./setting";
import receive from "./receive";
import history from "./history";

export default {
  data() {
    return {
      breadcrumbs: [],
      loaded: true,
      drawer: null,
      notifications: [1],
      pageTitle: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),
    items() {
      return menu;
    },
    trans() {
      return transfer;
    },
    contra() {
      return contract;
    },
    set() {
      return setting;
    },
    receive() {
      return receive;
    },
    history() {
      return history;
    },
  },
  created() {
    this.$root.$on("set-page-title", (pageTitle) => {
      this.pageTitle = pageTitle;
    });

    let path = "/" + this.$route.path.split("/")[1];
    let index = this._.findIndex(this.items, {link: path});
    if (index != -1) {
      this.pageTitle = this.items[index].title;
      this.breadcrumbs.push({
        text: this.items[index].title,
        disabled: true,
        href: path,
      });
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    updateLocation(item) {
      // this.pageChanged(0, item);
      this.pageTitle = item.title;
    },
    pageChanged(segment, location = null) {
      this.breadcrumbs.splice(segment);
      if (location != null) {
        this.pageTitle = location.title;
        this.breadcrumbs.map((item) => (item.disabled = false));
        this.breadcrumbs.push({
          text: location.title,
          disabled: true,
          href: location.link,
        });
      }

      document.title =
        this.breadcrumbs
          .slice()
          .reverse()
          .map((val) => val.text)
          .join(" / ") +
        " - " +
        process.env.VUE_APP_TITLE;
    },
  },
};
</script>

<style>
  .fs-1 {
    padding: 0 8px !important;
  }
  .fs-1 .v-list-item__title {
    font-size: 1rem !important;
    line-height: 1.25 !important;
  }
</style>