<template>
  <v-card rounded="lg" class="transparent">
    <v-card-title class="text-black"> Hi, {{this.user.account.fullname}}</v-card-title>
    <p class="ml-4">
      Welcome to MOMOFIN. Momofin is your digital transaction solution      
    </p>
    <v-card-text>
      <v-card v-show="status_account==='uncomplete'">
        <v-card-text>
          <p class="font-weight-medium text-black fs-16">
            Please activate your account by following this steps below to use our services
          </p>
          <!-- <v-progress-linear value="25"></v-progress-linear> -->
          <div class="row mb-2">
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-checkbox
                readonly
                v-model="ex4"
                label="Verify your email"
                color="success"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-checkbox
                readonly
                v-model="ex5"
                label="Your Business Details"
                color="success"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-checkbox
                readonly
                v-model="ex6"
                label="Your Personal Details"
                color="success"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="12"
              sm="3"
              md="3"
            >
              <v-checkbox
                readonly
                v-model="ex7"
                label="Upload Documents"
                color="success"
                hide-details
              ></v-checkbox>
            </v-col>
            <!-- <v-btn color="primary" outlined rounded @click="logout">
              Logout
            </v-btn> -->
          </div>
          <v-btn
            v-show="status_account==='uncomplete'"
            class="mt-3"
            color="primary"
            @click="dialog=true"
          >
            Continue Process
          </v-btn>
        </v-card-text>
      </v-card>
      <div class="my-3" v-show="status_account==='completed'">
        <v-col
          cols="12"
          md="12"
        >  
          <img src="@/assets/images/complete-activate.png" style="width: 100%;" />          
        </v-col>
      </div>
      <div class="my-3">
        <v-card class="transparent">
          <v-card-title class="text-black">Quick actions</v-card-title>
          <v-card-text>
            <v-item-group mandatory>
              <!-- <v-container> -->
                <v-row>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-item v-slot="{ active, toggle }">
                      <v-card
                        color="white"
                        class="d-flex align-center"
                        dark
                        height="150"
                        @click="toggle"
                        elevation="1"
                      >
                        <v-scroll-y-transition>
                          <div                            
                            class="flex-grow-1 text-center"
                          >
                            <img src="@/assets/images/materil.png" class="ma-2" />                            
                            <p class="text-center text-black font-weight-medium">eMaterai</p>                        
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-item v-slot="{ active, toggle }">
                      <v-card
                        color="white"
                        class="d-flex align-center"
                        dark
                        height="150"
                        @click="toggle"
                        elevation="1"
                      >
                        <v-scroll-y-transition>
                          <div                            
                            class="flex-grow-1 text-center"
                          >
                            <img src="@/assets/images/signature.png" class="ma-2" />
                            <p class="text-center text-black font-weight-medium">eSignature</p>         
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                  <v-col
                    cols="12"
                    md="2"
                  >
                    <v-item v-slot="{ active, toggle }">
                      <v-card
                        color="primary"
                        class="d-flex align-center"
                        dark
                        height="150"
                        @click="toggle"
                        elevation="1"
                      >
                        <v-scroll-y-transition>
                          <div
                            class="text-h4 flex-grow-1 text-center"
                          >
                            <v-icon large>ri-add-circle-fill</v-icon>
                          </div>
                        </v-scroll-y-transition>
                      </v-card>
                    </v-item>
                  </v-col>
                </v-row>
              <!-- </v-container> -->
            </v-item-group>
          </v-card-text>
        </v-card>
      </div>
      <div class="my-3">
        <v-card class="transparent">
          <v-card-title class="text-black">Explore Momofin Product</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  color="white"
                  elevation="1"
                >
                  <v-card-text>
                    <img src="@/assets/images/icon-transfer.png" class="" />                            
                    <p class="text-black font-weight-medium fs-18">
                      eTransfer
                    </p>
                    <p>
                      Phasellus accumsan imperdiet tempor. Cras tincidunt, arcu nec eleifend porttitor, orci est vehicula velit.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  color="white"
                  elevation="1"
                >
                  <v-card-text>
                    <img src="@/assets/images/icon-contract.png" class="" />                            
                    <p class="text-black font-weight-medium fs-18">
                      eContract
                    </p>
                    <p>
                      Phasellus accumsan imperdiet tempor. Cras tincidunt, arcu nec eleifend porttitor, orci est vehicula velit.
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div class="my-3">
        <v-card class="transparent">
          <v-card-title class="text-black">Learn about momofin</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  color="white"
                  elevation="1"
                >
                  <v-card-text>
                    <div class="row">
                      <v-col
                        cols="12"
                        md="2"
                        class="pr-0"
                      >                        
                        <img src="@/assets/images/icon-book.png" class="" />                            
                      </v-col>
                      <v-col
                        cols="12"
                        md="10"
                        class="pl-0"
                      >                     
                        <p class="text-black font-weight-medium fs-16">
                          Read Momofin Docs
                        </p>
                      </v-col>
                    </div>
                    <p>
                      Documentation of all financial products and how to use them
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  color="white"
                  elevation="1"
                >
                  <v-card-text>
                    <div class="row">
                      <v-col
                        cols="12"
                        md="2"
                        class="pr-0"
                      >                        
                        <img src="@/assets/images/icon-book.png" class="" />                            
                      </v-col>
                      <v-col
                        cols="12"
                        md="10"
                        class="pl-0"
                      >                     
                        <p class="text-black font-weight-medium fs-16">
                          Read API Reference
                        </p>
                      </v-col>
                    </div>
                    <p>
                      Documentation of all financial products and how to use them
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-card
                  color="white"
                  elevation="1"
                >
                  <v-card-text>
                    <div class="row">
                      <v-col
                        cols="12"
                        md="2"
                        class="pr-0"
                      >                        
                        <img src="@/assets/images/icon-book.png" class="" />                            
                      </v-col>
                      <v-col
                        cols="12"
                        md="10"
                        class="pl-0"
                      >                     
                        <p class="text-black font-weight-medium fs-16">
                          Help Desk
                        </p>
                      </v-col>
                    </div>
                    <p>
                      Documentation of all financial products and how to use them
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"  
      persistent    
    >
      <v-card style="background: #f5f7f9;">
        <v-toolbar class="transparent" elevation="0"
        >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              icon
              @click="dialog = false"
              color="primary"
            >
              <v-icon>ri-close-line</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-stepper v-model="e1" alt-labels class="transparent" elevation="0">
            <v-stepper-header elevation="0" style="box-shadow: none;">
                  <v-stepper-step
                    :complete="e1 > 1"
                    step="1"
                  >
                    Business Type
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e1 > 2"
                    step="2"
                  >
                    Business Details
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e1 > 3"
                    step="3"
                  >
                    Personal Details
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step
                    :complete="e1 > 4"
                    step="4"
                  >
                    Supporting Documents
                  </v-stepper-step>

                  <v-divider></v-divider>

                  <v-stepper-step step="5">
                    Summary
                  </v-stepper-step>
            </v-stepper-header>
                <v-stepper-items class="">
                  <v-stepper-content step="1">
                    <v-row class="">
                      <div class="col-md-8 pr-0" style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                        <v-card class="white rounded-l-xl pa-5">
                          <p class="text-black fs-18 font-weight-medium">Tell us a bit about your business</p>
                          <div class="col-md-3 pa-0 mb-3">
                            <v-divider style="border:2px solid #0068d6;"></v-divider>
                          </div>
                          <v-form
                            ref="form1"
                            v-model="valid1"
                            class="py-3"
                          >
                            <v-list dense>
                              <p class="mb-2 text-black mt-3">Type of your business</p>                        
                              <v-list-item-group
                                v-model="selectedItem"
                                color="primary"
                                class="d-flex"
                              >
                                <v-list-item value="individual" class="col-md-6 border-primary rounded-lg ma-1" outlined color="primary">
                                  <v-list-item-content>
                                    <v-list-item-icon>
                                      <p class="text-black font-weight-medium">                                  
                                        <v-icon class="text-black">ri-user-line </v-icon> Individual
                                      </p>
                                    </v-list-item-icon>
                                    <div class="font-weight-regular fs-12 mt-2" style="line-height: 16px;">
                                      I have not registered as a company or dont have any company legal documents
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item value="corporate" class="col-md-6 border-primary rounded-lg ma-1" outlined color="primary">
                                  <v-list-item-content>
                                    <v-list-item-icon>
                                      <p class="text-black font-weight-medium">                                  
                                        <v-icon class="text-black">ri-briefcase-5-line </v-icon> Corporate
                                      </p>
                                    </v-list-item-icon>
                                    <div class="font-weight-regular fs-12 mt-2" style="line-height: 16px;">
                                      I have registered as a PT, CV, Yayasan or foreign entity
                                    </div>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                            
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Business Type</p>
                                  <!-- v-model="bt_type"
                                  :items="bt_list"
                                  :item-text="'title'"
                                  :item-value="`${bidang_studi.id}, ${bidang_studi.title}`" -->
                                  <!-- v-model="bt_type" -->
                                <v-select
                                  v-model="bt_type"
                                  :items="type"
                                  :item-text="'name'"
                                  :item-value="'_id'"
                                  required
                                  :rules="[...rules('Business Type', 'required'), validate]"
                                  placeholder="Select business type/badan usaha"
                                  hide-details="auto"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">How many employees do you have?</p>
                                <v-select
                                  v-model="bt_size"
                                  :items="size"
                                  :item-text="'value'"
                                  :item-value="'_id'"
                                  placeholder="1-20"
                                  required
                                  :rules="[...rules('Employees', 'required'), validate]"
                                  hide-details="auto"                                  
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">How many transactions do you handle each month?</p>
                                <v-select
                                  v-model="bt_avg"
                                  :items="avg"
                                  :item-text="'value'"
                                  :item-value="'_id'"
                                  placeholder="Select"
                                  required
                                  :rules="[...rules('Transaction', 'required'), validate]"
                                  hide-details="auto"                                  
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">What is your average transaction size?</p>
                                <v-select
                                  v-model="bt_month"
                                  :items="month"
                                  :item-text="'value'"
                                  :item-value="'_id'"
                                  placeholder="Select"
                                  :rules="[...rules('Average Transaction', 'required'), validate]"
                                  hide-details="auto"
                                  required                                  
                                ></v-select>
                              </v-col>
                            </v-row>
                          <div class="align-end text-end my-3">                            
                            <v-btn
                              color="primary"
                              @click="step1()"
                              class="text-end"
                              :loading="submiting"
                            >
                              Save & Next
                            </v-btn>
                          </div>
                          </v-form>
                        </v-card>
                      </div>
                      <div class="col-md-4 pl-0 rounded-r-xl" style="background: rgba(251, 251, 251, 1);">
                        <v-card v-if="selectedItem ==='individual'" class="transparent rounded-r-xl pa-5">
                          <p class="fs-16 text-black font-weight-medium">Individual</p>                 
                          <p class="text-black font-weight-regular">Make sure to prepare documents below before continue to the next step:</p>       
                          <ul class="">
                            <li class="primary--text"><p class="mb-0 ">National ID Card or other equivalent ID</p></li>
                            <li class="primary--text"><p class="">Nomor Pokok Wajib Pajak</p></li>
                          </ul>
                        </v-card>
                        <v-card v-else-if="selectedItem ==='corporate'" class="transparent rounded-r-xl pa-5">
                          <p class="fs-16 text-black font-weight-medium">Corporate</p>                 
                          <p class="text-black font-weight-regular">Make sure to prepare documents below before continue to the next step:</p>       
                          <ul class="">
                            <li class="primary--text"><p class="mb-0 ">Owner's National ID Card or other equivalent ID</p></li>
                            <li class="primary--text"><p class="mb-0 ">Company's Nomor Pokok Wajib Pajak</p></li>
                            <li class="primary--text"><p class="mb-0 ">Surat Izin Usaha Perdagangan</p></li>
                            <li class="primary--text"><p class="mb-0 ">Nomor Induk Berusaha/Tanda Daftar Perusahaan</p></li>
                            <li class="primary--text"><p class="mb-0 ">Akta Pendirian Perseroan</p></li>
                          </ul>
                        </v-card>
                      </div>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-row class="">
                      <v-col cols="12" md="8" class="pr-0" style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                        <v-card class="white rounded-l-xl pa-5">
                          <p class="text-black fs-18 font-weight-medium">Business details</p>
                          <div class="col-md-3 pa-0 mb-3">
                            <v-divider style="border:2px solid #0068d6;"></v-divider>
                          </div>
                          <v-form
                            ref="form2"
                            v-model="valid2"
                            lazy-validation
                            class="py-3"
                          >                            
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Business name</p>
                                <v-text-field
                                  v-model="bd_name"
                                  filled
                                  outlined
                                  hide-details="auto"
                                  :rules="[...rules('Business Name', 'required'), validate]"
                                  placeholder="Your business name/brand"
                                ></v-text-field>                                  
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">How many employees do you have?</p>
                                <v-select
                                  v-model="bd_industry"
                                  :items="industry"
                                  :item-text="'name'"
                                  :item-value="'_id'"
                                  :rules="[...rules('Select Employees', 'required'), validate]"
                                  placeholder="Select your industry"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Business Address</p>
                                <v-textarea
                                  rows="3"
                                  v-model="bd_address"
                                  row-height="25"
                                  :rules="[...rules('Business Address', 'required'), validate]"
                                  placeholder="Your business address"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Business phone number</p>
                                <v-text-field
                                  v-model="bd_phone"
                                  :rules="[...rules('Business Phone Number', 'required'), validate]"
                                  filled
                                  outlined
                                  hide-details="auto"
                                  placeholder="+62 8123 456 7890"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-col class="px-0 py-3 my-5">
                              <v-divider class=""></v-divider>
                            </v-col>
                            <p class="text-black fs-18 font-weight-medium mt-5">Business details</p>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Bank Name</p>
                                <v-select
                                  v-model="bd_bank"
                                  :items="bank"
                                  :item-text="'name'"
                                  :item-value="'id'"
                                  placeholder="Select Bank Name"
                                  :rules="[...rules('Bank Name', 'required'), validate]"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Bank Account Number</p>
                                <v-text-field
                                  filled
                                  outlined
                                  hide-details="auto"
                                  placeholder="Bank Account Number"
                                  v-model="bd_bankCode"
                                  :rules="[...rules('Bank Code', 'required'), validate]"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <div class="row">
                            <div class="col-md-6">                              
                              <v-btn
                                color="primary"
                                @click="e1--"
                                class="text-end"
                                outlined
                              >
                                Cancel
                              </v-btn>
                            </div>                            
                            <div class="col-md-6 align-end text-end">                              
                              <v-btn
                                color="primary"
                                @click="step2()"
                                class="text-end"
                              >
                                Save & Next
                              </v-btn>
                            </div>                            
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="4" class="pl-0 rounded-r-xl" style="background: rgba(251, 251, 251, 1);">
                        <v-card class="transparent rounded-r-xl pa-5">
                          <p class="text-black font-weight-medium">Under <span class="primary--text">Bank Indonesia regulations</span>, we need to verify your data before you can make a transaction.</p>                                 
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <v-row class="">
                      <v-col cols="12" md="8" class="pr-0" style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                        <v-card class="white rounded-l-xl pa-5">
                          <p class="text-black fs-18 font-weight-medium">Personal details</p>
                          <div class="col-md-3 pa-0 mb-3">
                            <v-divider style="border:2px solid #0068d6;"></v-divider>
                          </div>
                          <v-form
                            ref="form3"
                            v-model="valid3"
                            lazy-validation
                            class="py-3"
                          >                            
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Your role</p>
                                <v-select
                                  v-model="pd_role"
                                  :items="role"
                                  :item-text="'name'"
                                  :item-value="'_id'"
                                  :rules="[...rules('Your Role', 'required'), validate]"
                                  placeholder="Founder/Owner/Director"
                                ></v-select>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Full Name</p>
                                <v-text-field
                                  :rules="[...rules('Fullname', 'required'), validate]"
                                  v-model="pd_fullname"
                                  filled
                                  outlined
                                  hide-details="auto"
                                  placeholder="Fullname"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Email Address</p>
                                <v-text-field
                                  filled
                                  outlined
                                  hide-details="auto"
                                  placeholder="example@mailaddress.com"
                                  :rules="emailRules"
                                  v-model="pd_email"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Your phone number</p>
                                <v-text-field
                                  filled
                                  outlined
                                  hide-details="auto"
                                  placeholder="+62 8123 456 7890"
                                  v-model="pd_phone"
                                ></v-text-field>
                                  <!-- :rules="[...rules('Phone Number', 'required'), validate]" -->
                              </v-col>
                            </v-row>                            
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Date of Birth</p>
                                <!-- <v-text-field
                                  filled
                                  outlined
                                  hide-details="auto"
                                  placeholder="21 Juli 2021"                                  
                                ></v-text-field> -->
                                <v-menu                       
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  elevation="0"
                                >
                                  <v-date-picker
                                    v-model="pd_date"
                                    ref="picker"
                                    min="1950-01-01"                          
                                    :max="(new Date()).toISOString()"
                                    outlined
                                    :rules="[...rules('Date of Birth', 'required'), validate]"
                                    required
                                    @change="save"
                                  ></v-date-picker>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      v-model="pd_date"
                                      append-icon="ri-calendar-line"
                                      :rules="[...rules('Date of Birth', 'required'), validate]"
                                      readonly
                                      outlined
                                      v-bind="attrs"
                                      v-on="on"
                                      class=""
                                      placeholder="21 Juli 2021"                                                                        
                                    ></v-text-field>
                                  </template>
                                </v-menu>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">KTP Number</p>
                                <v-text-field
                                  filled
                                  outlined
                                  hide-details="auto"
                                  placeholder="eg. 1234560112990001"
                                  v-model="pd_ktp"
                                ></v-text-field>
                                  <!-- :rules="[...rules('KTP Number', 'required'), validate]" -->
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">NPWP Number</p>
                                <v-text-field
                                  filled
                                  outlined
                                  hide-details="auto"
                                  placeholder="eg. 00.111.222.3-444.000"
                                  v-model="pd_npwp"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <div class="row">
                            <div class="col-md-6">                              
                              <v-btn
                                color="primary"
                                @click="e1--"
                                class="text-end"
                                outlined
                              >
                                Cancel
                              </v-btn>
                            </div>                            
                            <div class="col-md-6 align-end text-end">                              
                              <v-btn
                                color="primary"
                                @click="step3()"
                                class="text-end"
                              >
                                Save & Next
                              </v-btn>
                            </div>                            
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="4" class="pl-0 rounded-r-xl" style="background: rgba(251, 251, 251, 1);">
                        <v-card class="transparent rounded-r-xl pa-5">
                          <p class="text-black font-weight-medium">Please provide the details of the person authorised by your business entity to sign our Service Agreement, e.g. Business Owner or Director of Entity</p>                                 
                        </v-card>
                      </v-col>
                    </v-row>        
                  </v-stepper-content>

                  <v-stepper-content step="4">
                    <v-row class="">
                      <v-col cols="12" md="8" class="pr-0" style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                        <v-card class="white rounded-l-xl pa-5">
                          <p class="text-black fs-18 font-weight-medium">Upload Documents for Verification</p>
                          <div class="col-md-3 pa-0 mb-3">
                            <v-divider style="border:2px solid #0068d6;"></v-divider>
                          </div>
                          <v-form
                            ref="form4"
                            v-model="valid4"
                            lazy-validation
                            class="py-3"
                          >
                            <p class="text-black fs-16 font-weight-medium mb-1">Authorized Person Documents</p>                            
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Owner's National ID Card (KTP)</p>
                                <div
                                  :class="{'has-file': showRemovedBtn}"
                                  class="c-file-input js-file-input"
                                  @dragover.prevent @drop="onDrop"
                                  >
                                  <div class="c-file-input__indicator">
                                    <span class="c-file-input__indicator__icon c-icon c-icon--attach"></span>
                                  </div>
                                  <label class="c-file-input__label js-file-input__label" for="inputfile">
                                    <span>{{ theFileName }}</span>
                                    <input
                                      id="inputfile"
                                      type="file" @change="fileChangeHandler"
                                      name="attachment" class="c-file-input__field js-file-input__field">
                                  </label>
                                  <!-- <div @click.stop="clearFileHandler" class="c-file-input__remove js-file-input__remove">
                                    <span class="c-file-input__remove__icon c-icon c-icon--remove-circle"></span>
                                    <v-icon class="c-file-input__remove__icon c-icon c-icon--remove-circle">ri-close-circle-fill</v-icon>
                                  </div> -->
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Owner's NPWP</p>
                                <div
                                  :class="{'has-file': showRemovedBtn}"
                                  class="c-file-input js-file-input"
                                  @dragover.prevent @drop="onDrop"
                                  >
                                  <div class="c-file-input__indicator">
                                    <span class="c-file-input__indicator__icon c-icon c-icon--attach"></span>
                                  </div>
                                  <label class="c-file-input__label js-file-input__label" for="inputfile">
                                    <span>{{ theFileName }}</span>
                                    <input
                                      id="inputfile2"
                                      type="file" @change="fileChangeHandler"
                                      name="attachment" class="c-file-input__field js-file-input__field">
                                  </label>
                                  <!-- <div @click.stop="clearFileHandler" class="c-file-input__remove js-file-input__remove">
                                    <span class="c-file-input__remove__icon c-icon c-icon--remove-circle"></span>
                                    <v-icon class="c-file-input__remove__icon c-icon c-icon--remove-circle">ri-close-circle-fill</v-icon>
                                  </div> -->
                                </div>
                              </v-col>
                            </v-row>
                            <p class="text-black fs-16 font-weight-medium mb-1 mt-5 pt-3">Authorized Company Documents</p>                                                        
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Surat Izin Usaha Perdagangan (SIUP)</p>
                                <div
                                  :class="{'has-file': showRemovedBtn}"
                                  class="c-file-input js-file-input"
                                  @dragover.prevent @drop="onDrop"
                                  >
                                  <div class="c-file-input__indicator">
                                    <span class="c-file-input__indicator__icon c-icon c-icon--attach"></span>
                                  </div>
                                  <label class="c-file-input__label js-file-input__label" for="inputfile">
                                    <span>{{ theFileName }}</span>
                                    <input
                                      id="inputfile"
                                      type="file" @change="fileChangeHandler"
                                      name="attachment" class="c-file-input__field js-file-input__field">
                                  </label>
                                  <!-- <div @click.stop="clearFileHandler" class="c-file-input__remove js-file-input__remove">
                                    <span class="c-file-input__remove__icon c-icon c-icon--remove-circle"></span>
                                    <v-icon class="c-file-input__remove__icon c-icon c-icon--remove-circle">ri-close-circle-fill</v-icon>
                                  </div> -->
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Nomor Induk Berusaha/Tanda Daftar Perusahaan</p>
                                <div
                                  :class="{'has-file': showRemovedBtn}"
                                  class="c-file-input js-file-input"
                                  @dragover.prevent @drop="onDrop"
                                  >
                                  <div class="c-file-input__indicator">
                                    <span class="c-file-input__indicator__icon c-icon c-icon--attach"></span>
                                  </div>
                                  <label class="c-file-input__label js-file-input__label" for="inputfile">
                                    <span>{{ theFileName }}</span>
                                    <input
                                      id="inputfile"
                                      type="file" @change="fileChangeHandler"
                                      name="attachment" class="c-file-input__field js-file-input__field">
                                  </label>
                                  <!-- <div @click.stop="clearFileHandler" class="c-file-input__remove js-file-input__remove">
                                    <span class="c-file-input__remove__icon c-icon c-icon--remove-circle"></span>
                                    <v-icon class="c-file-input__remove__icon c-icon c-icon--remove-circle">ri-close-circle-fill</v-icon>
                                  </div> -->
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Akta Pendirian</p>
                                <div
                                  :class="{'has-file': showRemovedBtn}"
                                  class="c-file-input js-file-input"
                                  @dragover.prevent @drop="onDrop"
                                  >
                                  <div class="c-file-input__indicator">
                                    <span class="c-file-input__indicator__icon c-icon c-icon--attach"></span>
                                  </div>
                                  <label class="c-file-input__label js-file-input__label" for="inputfile">
                                    <span>{{ theFileName }}</span>
                                    <input
                                      id="inputfile"
                                      type="file" @change="fileChangeHandler"
                                      name="attachment" class="c-file-input__field js-file-input__field">
                                  </label>
                                  <!-- <div @click.stop="clearFileHandler" class="c-file-input__remove js-file-input__remove">
                                    <span class="c-file-input__remove__icon c-icon c-icon--remove-circle"></span>
                                    <v-icon class="c-file-input__remove__icon c-icon c-icon--remove-circle">ri-close-circle-fill</v-icon>
                                  </div> -->
                                </div>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col>
                                <p class="mb-2 text-black mt-3">Corporate NPWP</p>
                                <div
                                  :class="{'has-file': showRemovedBtn}"
                                  class="c-file-input js-file-input"
                                  @dragover.prevent @drop="onDrop"
                                  >
                                  <div class="c-file-input__indicator">
                                    <span class="c-file-input__indicator__icon c-icon c-icon--attach"></span>
                                  </div>
                                  <label class="c-file-input__label js-file-input__label" for="inputfile">
                                    <span>{{ theFileName }}</span>
                                    <input
                                      id="inputfile"
                                      type="file" @change="fileChangeHandler"
                                      name="attachment" class="c-file-input__field js-file-input__field">
                                  </label>
                                  <!-- <div @click.stop="clearFileHandler" class="c-file-input__remove js-file-input__remove">
                                    <span class="c-file-input__remove__icon c-icon c-icon--remove-circle"></span>
                                    <v-icon class="c-file-input__remove__icon c-icon c-icon--remove-circle">ri-close-circle-fill</v-icon>
                                  </div> -->
                                </div>
                              </v-col>
                            </v-row>
                          </v-form>
                          <div class="row">
                            <div class="col-md-6">                              
                              <v-btn
                                color="primary"
                                @click="e1--"
                                class="text-end"
                                outlined
                              >
                                Cancel
                              </v-btn>
                            </div>                            
                            <div class="col-md-6 align-end text-end">                              
                              <v-btn
                                color="primary"
                                @click="step4()"
                                class="text-end"
                              >
                                Save & Next
                              </v-btn>
                            </div>                            
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="4" class="pl-0 rounded-r-xl" style="background: rgba(251, 251, 251, 1);">
                        <v-card class="transparent rounded-r-xl pa-5">
                          <p class="text-black font-weight-medium">All documents listed are required, and incomplete submission may result in a longer processing time.</p>
                          <p class="text-black font-weight-bold">File format must be in .jpg, .png or pdf. Maximum of 8MB in file size.</p>
                          <div>
                            <ul type="none" class="pl-0">
                              <li class="primary--text"><p>Standard documents</p></li>
                              <li class="primary--text"><p>NPWP of Autorized Signatory</p></li>
                              <li class="primary--text"><p>ID Card (KTP) of authorized signatory</p></li>
                              <li class="primary--text"><p>Industry-specific documents</p></li>
                              <li class="primary--text"><p>Tanda Daftar Usaha Pariwisata (TDUP)</p></li>
                            </ul>  
                          </div>                                   
                        </v-card>
                      </v-col>
                    </v-row>

                    <!-- <v-btn
                      color="primary"
                      @click="e1 = 3"
                    >
                      Continue
                    </v-btn>

                    <v-btn text>
                      Cancel
                    </v-btn> -->
                  </v-stepper-content>

                  <v-stepper-content step="5">
                    <v-row class="">
                      <v-col cols="12" md="8" class="pr-0" style="border-right: 1px solid rgba(0, 0, 0, 0.12)">
                        <v-card class="white rounded-l-xl pa-5">
                          <p class="text-black fs-18 font-weight-medium">Confirm Information To Be Submitted</p>
                          <div class="col-md-3 pa-0 mb-3">
                            <v-divider style="border:2px solid #0068d6;"></v-divider>
                          </div>
                          <div>                                                      
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      <p class="text-black fs-16 font-weight-medium mb-1">Business Detail</p>
                                    </th>
                                    <th class="text-right">
                                      <v-btn outlined color="primary" class="rounded-0" @click="e1=2">
                                        <v-icon>ri-pencil-line </v-icon> Edit
                                      </v-btn>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Business name</td>
                                    <td class="text-black">{{ bd_name }}</td>
                                  </tr>
                                  <tr>
                                    <td>Industry</td>
                                    <td class="text-black">Fintech</td>
                                  </tr>
                                  <tr>
                                    <td>Address</td>
                                    <td class="text-black">{{ bd_address }}</td>
                                  </tr>
                                  <tr>
                                    <td>Phone number</td>
                                    <td class="text-black">{{ bd_phone }}</td>
                                  </tr>
                                  <tr>
                                    <td>Bank Account</td>
                                    <td class="text-black">{{bd_bankCode}}</td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                          <div>                                                      
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      <p class="text-black fs-16 font-weight-medium mb-1">Personal Detail</p>
                                    </th>
                                    <th class="text-right">
                                      <v-btn outlined color="primary" class="rounded-0" @click="e1=3">
                                        <v-icon>ri-pencil-line </v-icon> Edit
                                      </v-btn>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Role within company</td>
                                    <td class="text-black">Momofin</td>
                                  </tr>
                                  <tr>
                                    <td>Full Name</td>
                                    <td class="text-black">{{pd_fullname}}</td>
                                  </tr>
                                  <tr>
                                    <td>Email Address</td>
                                    <td class="text-black">Jl. Ta’aruf no.123 Sleman DI Yogyakarta, 55588</td>
                                  </tr>
                                  <tr>
                                    <td>Mobile number</td>
                                    <td class="text-black">+62 8123 456 7890</td>
                                  </tr>
                                  <tr>
                                    <td>Date of Birth</td>
                                    <td class="text-black">BCA 1234567890</td>
                                  </tr>
                                  <tr>
                                    <td>KTP number</td>
                                    <td class="text-black">+62 8123 456 7890</td>
                                  </tr>
                                  <tr>
                                    <td>NPWP number</td>
                                    <td class="text-black">+62 8123 456 7890</td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                          <div>                                                      
                            <v-simple-table>
                              <template v-slot:default>
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      <p class="text-black fs-16 font-weight-medium mb-1">Documents</p>
                                    </th>
                                    <th class="text-right">
                                      <v-btn outlined color="primary" class="rounded-0" @click="e1=4">
                                        <v-icon>ri-pencil-line </v-icon> Edit
                                      </v-btn>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Owner's National ID Card</td>
                                    <td class="text-black" style="border:3px dotted #4c6583">Momofin</td>
                                  </tr>
                                  <tr>
                                    <td>Owner's NPWP</td>
                                    <td class="text-black">Fintech</td>
                                  </tr>
                                  <tr>
                                    <td>Surat Izin Usaha Perdagangan (SIUP)</td>
                                    <td class="text-black">Jl. Ta’aruf no.123 Sleman DI Yogyakarta, 55588</td>
                                  </tr>
                                  <tr>
                                    <td>NIB/TDP</td>
                                    <td class="text-black">+62 8123 456 7890</td>
                                  </tr>
                                  <tr>
                                    <td>Akta Pendirian</td>
                                    <td class="text-black">BCA 1234567890</td>
                                  </tr>
                                  <tr>
                                    <td>Corporate NPWP</td>
                                    <td class="text-black">+62 8123 456 7890</td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </div>
                          <div class="pa-3 my-5">                            
                            <p class="text-black">
                              By submitting this form, you agree to the <span class="primary--text"> Momofin Services Agreement </span>, to receiving text messages from Momofin, and you certify that the information provided is complete and correct.
                            </p>                       
                          </div>
                        </v-card>
                      </v-col>
                      <v-col cols="12" md="4" class="pl-0 rounded-r-xl" style="background: rgba(251, 251, 251, 1);">
                        <v-card class="transparent rounded-r-xl pa-5">
                          <p class="text-black font-weight-medium">Please check that your details are correct before proceeding as incorrect or insufficient documents submitted could result in longer processing time.</p>                                 
                        </v-card>
                      </v-col>
                    </v-row>                
                  </v-stepper-content>
                </v-stepper-items>                
          </v-stepper>
          <div class="col-md-12 align-end text-end mb-3">            
            <v-btn text class="rounded-0 float-right" color="primary" @click="dialog=false;">
              Skip for now
            </v-btn>        
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import validationMixin from "../_mixins/validation";
import { mapGetters } from "vuex";

export default {
  name: "dashboard",
  mixins: [validationMixin],  
  props: {
    labelPlaceholder: {
      type: String,
      default: 'Add File or drag and drop file here',
    },
    labelPlaceholder2: {
      type: String,
      default: 'Add File or drag and drop file here',
    },
  },
  data() {
    return {
      e1:1,
      ex4:false,
      ex5:false,
      ex6:false,
      ex7:false,
      selectedItem:'individual',
      dialog:false,
      // items:[1,2,3],
      image:'',
      value: '',
      files: [],
      files2: [],

      submiting:false,
      valid:true,
      valid1:true,
      valid2:true,
      valid3:true,
      valid4:true,
      errors: null,
      showRemovedBtn: false,
      showRemovedBtn2: false,
      type:[],
      size:[],
      month:[],
      avg:[],

      // step 1
      bt_type:'',
      bt_month:'',
      bt_avg:'',
      bt_size:'',
      fullname:'',

      industry:[],
      bank:[],
      role:[],

      status_account:'',

      // step 2
      bd_name:'',
      bd_phone:'',
      bd_address:'',
      bd_industry:'',
      bd_bank:'',
      bd_bankCode:'',

      // step 3
      pd_role:'',
      pd_fullname:'',
      pd_email:'',
      pd_phone:'',
      pd_date:'',
      pd_ktp:'',
      pd_npwp:'',
      menu: false,
      date: null,
      emailRules: [
        (v) => !!v || "Email Tidak Boleh Kosong",
        v => /.+@.+\..+/.test(v) || 'E-mail tidak valid',
      ],

      //step 4 
      owner_npwp:'',
      owner_id_card:'',
      siup:'',
      tdup:'',
      akta:'',
      corporate_npwp:''


    };
  },
  mounted() {
    this.getBT();
    this.getDetail();
    // console.log(this.$store.getters.isLoggedIn);
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    ...mapGetters(["user"]),

    theFileName() {
      if ((this.files.length > 0) && 'name' in this.files[0]) {
        return this.files[0].name;
      }
      return this.labelPlaceholder;
    },
    theFileName2() {
      if ((this.files2.length > 0) && 'name' in this.files2[0]) {
        return this.files2[0].name;
      }
      return this.labelPlaceholder2;
    },
    validate() {
      return !this.errors || this.errors.message;
    },
  },
  methods: {
    save (date) {
        this.$refs.menu.save(date)
      },
    getBT(){
      this.axios
        .get(`/auth/activation/options`)
        .then((res) =>{
          // step 1
          this.type=res.data.data.activation_option[0].options.organization;      
          this.size=res.data.data.activation_option[0].options.organization_size;      
          this.avg=res.data.data.activation_option[0].options.transaction_volume_avg;      
          this.month=res.data.data.activation_option[0].options.transaction_volume;

          // step 2
          // console.log('type',res.data.data.activation_option[1])
          this.industry=res.data.data.activation_option[1].options.industry;
          this.bank=res.data.data.activation_option[1].options.bank;

          //step 3
          this.role=res.data.data.activation_option[2].options.company_roles;

        })
    },
    getDetail(){
      this.axios
        .get(`/auth/activation/detail`)
        .then((res) =>{
          this.status_account=res.data.data.activation_status;
          // console.log(this.status_account)
          if (res.data.data.activation_steps[0].status === "completed") {
            this.ex4=true;
          }
          if (res.data.data.activation_steps[1].status === "completed") {
            this.ex5=true;
          }
          if (res.data.data.activation_steps[2].status === "completed") {
            this.ex6=true;
          }
          if (res.data.data.activation_steps[3].status === "completed") {
            this.ex7=true;
          }


          // step 1
          this.selectedItem=res.data.data.activation_steps[0].data.account_type || '';
          this.bt_type=res.data.data.activation_steps[0].data.organization_type._id || '';
          this.bt_month=res.data.data.activation_steps[0].data.transaction_volume._id || '';
          this.bt_avg=res.data.data.activation_steps[0].data.average_transaction_volume._id || '';
          this.bt_size=res.data.data.activation_steps[0].data.organization_size._id || '';

          // step 2
          this.bd_name=res.data.data.activation_steps[1].data.name || '';
          this.bd_phone=res.data.data.activation_steps[1].data.phone_number || '';
          this.bd_address=res.data.data.activation_steps[1].data.address || '';
          this.bd_industry=res.data.data.activation_steps[1].data.industry_type || '';
          this.bd_bank=res.data.data.activation_steps[1].data.bank.bank_id || '';
          this.bd_bankCode=res.data.data.activation_steps[1].data.bank.account_number || '';

          // step 3
          this.pd_role=res.data.data.activation_steps[2].data.company_role[0]._id || '';
          this.pd_fullname=res.data.data.activation_steps[2].data.fullname || '';
          this.pd_email=res.data.data.activation_steps[2].data.email || '';
          this.pd_phone=res.data.data.activation_steps[2].data.phone_number || '';
          this.pd_date=res.data.data.activation_steps[2].data.birth_date || '';
          this.pd_ktp=res.data.data.activation_steps[2].data.id_card_number || '';
          this.pd_npwp=res.data.data.activation_steps[2].data.tax_number || '';

          if (res.data.data.activation_status==="uncomplete") {
            this.dialog=true;
          }
        })
    },
    onDrop: function(e) {
        e.stopPropagation();
        e.preventDefault();
        var files = e.dataTransfer.files;

        this.fileChangeHandler(files[0]);
    },
    fileChangeHandler(e) {
      this.files = Array.from(e.target.files);
      var file = e.target.files;
      this.owner_npwp=file[0];
      console.log('file',this.owner_npwp)      
      this.showRemovedBtn = true;
    },
    clearFileHandler() {
      const el = this.$el.querySelector('.js-file-input__field');
      const wrapper = document.createElement('form');
      this.wrapInputAndReset(el, wrapper);
    },
    wrapInputAndReset(el, wrapper) {
      // wrap input with form tag
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);

      // reset input with form.reset()
      wrapper.reset();

      // place childNodes in document fragment
      const docFrag = document.createDocumentFragment();
      while (wrapper.firstChild) {
        const child = wrapper.removeChild(wrapper.firstChild);
        docFrag.appendChild(child);
      }

      // replace wrapper with document fragment
      wrapper.parentNode.replaceChild(docFrag, wrapper);

      this.files = [];
      this.showRemovedBtn = false;
    },
    onDrop2: function(e) {
        e.stopPropagation();
        e.preventDefault();
        var files = e.dataTransfer.files;

        this.fileChangeHandler2(files[0]);
    },
    fileChangeHandler2(e) {
      this.files2 = Array.from(e.target.files);
      var file = e.target.files;
      this.owner_id_card=file[0];
      console.log('file2',this.owner_id_card)      
      this.showRemovedBtn2 = true;
    },
    clearFileHandler2() {
      const el = this.$el.querySelector('.js-file-input__field');
      const wrapper = document.createElement('form2');
      this.wrapInputAndReset2(el, wrapper);
    },
    wrapInputAndReset2(el, wrapper) {
      // wrap input with form tag
      el.parentNode.insertBefore(wrapper, el);
      wrapper.appendChild(el);

      // reset input with form.reset()
      wrapper.reset();

      // place childNodes in document fragment
      const docFrag = document.createDocumentFragment();
      while (wrapper.firstChild) {
        const child = wrapper.removeChild(wrapper.firstChild);
        docFrag.appendChild(child);
      }

      // replace wrapper with document fragment
      wrapper.parentNode.replaceChild(docFrag, wrapper);

      this.files2 = [];
      this.showRemovedBtn2 = false;
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/");
      });
    },
    user_type(){
      // this.errors = null;

      setTimeout(() => {
      if (this.$refs.form1.validate()) {
        this.submiting = true;
        let data = {
          business_type: this.selectedItem, 
          organization_type: this.bt_type,
          organization_size:this.bt_size,
          transaction_volume:this.bt_month,
          average_transaction_volume:this.bt_avg
        };
        // console.log('business',data)

        this.axios
          .post(
            `auth/activation/business`,data,
            {
              headers: {"Content-Type": "application/json"},
            })
          .then(() => {
            this.submiting = false;            
            this.e1++;
          })
          .catch((error) => {
            this.submiting = false;
            console.log('er',error.response.data)            
            this.response = error.response.data.message;
            setTimeout( () => {                
              this.response=null;
            }
            , 3000);
          });
        } 
      }, 50);
    },
    step1(){
      // this.errors = null;
      setTimeout(() => {
      if (this.$refs.form1.validate()) {
        this.submiting = true;
        let data = {
          business_type: this.selectedItem, 
          organization_type: this.bt_type,
          organization_size:this.bt_size,
          transaction_volume:this.bt_month,
          average_transaction_volume:this.bt_avg
        };
        // console.log('business',data)
        this.axios
          .post(
            `auth/activation/business`,data,
            {
              headers: {"Content-Type": "application/json"},
            })
          .then(() => {
            this.submiting = false;            
            this.e1++;
          })
          .catch((error) => {
            this.submiting = false;
            console.log('er',error.response.data)            
            this.response = error.response.data.message;
            setTimeout( () => {                
              this.response=null;
            }
            , 3000);
          });
        } 
      }, 50);
    },
    step2(){
      // this.errors = null;

      setTimeout(() => {
      if (this.$refs.form2.validate()) {
        this.submiting = true;
        let data = {
          name:this.bd_name,
          industry_type:this.bd_industry,
          bank_id:this.bd_bank,
          account_name:this.bd_bank,
          account_number:this.bd_bankCode
        };
        // console.log('business detail',data)

        this.axios
          .post(
            `auth/activation/business_detail`,data,
            {
              headers: {"Content-Type": "application/json"},
            })
          .then(() => {
            this.submiting = false;            
            this.e1++;
          })
          .catch((error) => {
            this.submiting = false;
            console.log('er',error.response.data)            
            this.response = error.response.data.message;
            setTimeout( () => {                
              this.response=null;
            }
            , 3000);
          });
        } 
      }, 50);
    },
    step3(){
      setTimeout(() => {
      if (this.$refs.form3.validate()) {
        this.submiting = true;
        let data = {
          fullname:this.pd_fullname,
          email:this.pd_email,
          birth_date:this.pd_date,
          company_role:this.pd_role,
          id_card_number:this.pd_ktp,
          tax_number:this.pd_npwp,
          phone_number:this.pd_phone,
        };
        console.log('personal detail',data)

        this.axios
          .post(
            `auth/activation/personal`,data,
            {
              headers: {"Content-Type": "application/json"},
            })
          .then(() => {
            this.submiting = false;            
            this.e1++;
          })
          .catch((error) => {
            this.submiting = false;
            console.log('er',error.response.data)            
            this.response = error.response.data.message;
            setTimeout( () => {                
              this.response=null;
            }
            , 3000);
          });
        } 
      }, 50);
    },
    step4(){
      setTimeout(() => {
      if (this.$refs.form4.validate()) {
        this.submiting = true;
        var formData = new FormData();
        let a = this.owner_npwp;
        let b = this.owner_id_card;
        let c = this.siup;
        let d = this.tdup;
        let e = this.akta;
        let f = this.corporate_npwp;
        console.log('img',a);

        formData.append("owner_npwp", a, "npwp.jpg");
        formData.append("owner_id_card", b, "id.jpg");
        formData.append("siup", c, "siup.jpg");
        formData.append("tdup", d, "tdup.jpg");
        formData.append("akta", e, "akta.jpg");
        formData.append("corporate_npwp", f, "corporate.jpg");
        // let data = {
        //   owner_npwp:'',
        // };
        // console.log('document',data)

        this.axios
          .post(
            `auth/activation/documents`,formData,
            {
              headers: {"Content-Type": "application/json"},
            })
          .then(() => {
            this.submiting = false;            
            this.e1++;
          })
          .catch((error) => {
            this.submiting = false;
            console.log('er',error.response.data)            
            this.response = error.response.data.message;
            setTimeout( () => {                
              this.response=null;
            }
            , 3000);
          });
        } 
      }, 50);
    }
  },
};
</script>

<style>
.v-stepper__step__step {
  font-size: 1.25rem !important;
  height: 30px !important;
  min-width: 30px !important;
  width: 30px !important;
}
</style>