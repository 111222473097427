<template>
  <v-card rounded="lg" class="transparent">
    <v-breadcrumbs :items="items" class="px-0">
      <template v-slot:divider>
        <v-icon>ri-arrow-right-s-line</v-icon>
      </template>
    </v-breadcrumbs>
    <v-card-title class="text-black pt-0 px-0 fs-20 font-weight-bold">Request Transfer</v-card-title>

    <v-card-text class="px-0">
      <div class="content">
        <div class="table my-3">          
          <v-card class="mt-5">
            <v-card-title class="font-weight-regular">
              <div class="row">
                <div class="col-md-6">
                  <p class="fs-16 text-black font-weight-medium"></p>
                </div>
                <div class="col-md-6 text-right">
                  <v-btn color="primary" class="white--text ma-1" :to="{ name: 'create-request-transfer'}">
                    Create Request Transfer
                  </v-btn>
                </div>
              </div>
              <div class="col-md-12 px-0">
                <v-divider></v-divider>
              </div>
              <div class="row">
                <div class="col-md-6 px-0">
                  <div class="d-flex">
                    <v-select
                      v-model="columnFilter.status"
                      :items="ex"
                      :item-value="`value`"
                      item-text="label"
                      placeholder="Select Status"
                      @change="getListPaymentLink(columnFilter.ex)"
                      class="ma-1"
                    ></v-select>           
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      elevation="0"
                    >
                      <v-date-picker
                        ref="picker"
                        v-model="columnFilter.date"
                        min="1950-01-01"
                        outlined
                        required
                        range
                        @change="getListPaymentLink(columnFilter.date)"
                      ></v-date-picker>
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateRangeText"
                          placeholder="Start date - End date"                    
                          append-icon="ri-calendar-line"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-1"
                        ></v-text-field>                   
                      </template>
                    </v-menu>
                  </div>                      
                </div>
                <div class="col-md-6 px-0 align-end text-end">
                  <div class="d-flex">                  
                    <v-text-field
                      outlined
                      v-model="search"
                      append-icon="$search"
                      class="mt-1 ms-2 mx-width-85"
                      placeholder="Search"
                      solo
                      flat
                    ></v-text-field>
                    <v-btn color="grey darken-4" class="white--text ma-1" @click="dialog=true" style="min-height: 48px;">
                      <v-icon class="mr-2">ri-download-cloud-fill </v-icon> Export
                    </v-btn>
                  </div>                  
                </div>
              </div>
            </v-card-title>
            
            <div v-if="!listPayment" class="col-md-6 mx-auto my-2">
              <img src="@/assets/images/empty.png"> 
              <p class="font-weight-bold text-black mb-2">No Disbursement transaction found.</p>
              <p class="text-semu">All transaction of your Momofin account will appear here.</p>
              <v-btn color="primary" class="ma-1" outlined>
                Create Disbursement
              </v-btn>
            </div>
            
            <v-data-table
              v-else
              :headers="headers"
              :items="listPayment"
              :search="search"
              :loading="isLoading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:[`item.payment_method`]="{ item }">
                <div v-if="item.payment_method === null">
                  -
                </div>
                <div v-else>
                  {{ item.payment_method }}
                </div>
              </template>

              <template v-slot:[`item.total_amount`]="{ item }">
                {{ formatPrice(item.total_amount) }}
              </template>

              <!-- <template v-slot:item.id="{ item }"> -->
              <template v-slot:[`item.code`]="{ item }">
                <router-link
                  class=""
                  :to="{ name: 'request-transfer-detail', params: { id: item.id } }"
                >
                {{item.code}}
                </router-link>
              </template>

              <!-- <template v-slot:item.status="{ item }"> -->
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="item.status === 'failed'"
                  color="#B71C1C"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-if="item.status === 'refunded'"
                  color="#006064"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-if="item.status === 'canceled'"
                  color="#EC407A"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else-if="item.status === 'pending'"
                  color="#F0AB0B"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
                <v-chip
                  v-else
                  :color="item.status == 'complete' ? 'success' : '#ADADAD'"
                  outlined
                  class="text-capitalize"
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </v-card>
        </div>        
      </div>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      width="500"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          @click="dialog=false"
        >
          <v-icon>ri-close-line</v-icon>
        </v-btn>
      </template> -->

      <v-card>
        <v-card-title class="text-h5">
          Export Transaction History Report
        </v-card-title>

        <v-card-text class="my-3">
          <p class="mb-0">Select File Type</p>
          <v-select
            :items="ex"
            placeholder="Select Type"
            class="ma-1"
          ></v-select>  
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            @click="dialog = false"
            class="text-center"
          >
            Export
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import store from "../../store";
import { formatPrice } from "@/lib/formatDate";

export default {
  data: () => ({
    dialog:false,
    menu:false,
    items: [
      {
        text: 'E-Transfer',
        disabled: false,
        href: '#',
      },
      {
        text: 'Receive Money',
        disabled: false,
        href: '#',
      },
      {
        text: 'Request Transfer',
        disabled: true,
        href: '#',
      }
    ],
    ex: [],
    search: '',
    headers: [          
      { text: 'CREATED DATE', value: 'createdAt' },
      { text: 'TRANSACTION ID', value: 'code' },
      { text: 'DESCRIPTION', value: 'description' },
      { text: 'CUSTOMER EMAIL', value: 'email' },
      { text: 'CHANNEL', value: 'payment_method' },
      { text: 'AMOUNT', value: 'total_amount', align: 'right' },
      { text: 'STATUS', value: 'status' },
    ],
    dates:[],
    listPayment: [],
    columnFilter: {
      status: [],
      date: [],
    },
    isLoading:false,
  }),
  mounted() {
    this.getListPaymentLink();
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },
  methods: {
    getListPaymentLink() {
      this.isLoading = true;
      this.$store.dispatch("getListPaymentLink", this.columnFilter)
      .then(response => {
        if (response.data.code == 401) {
          this.isError = true;
          this.showMessage = response.data.message;
          setTimeout( () => {
            localStorage.removeItem("momofin_auth");
            delete axios.defaults.headers.common["x-access-token"];
            store.commit("RESET_USER");
            this.$router.push({name: "login"})
          }, 1000);
        } else {
          const { data } = response;
          this.listPayment = data.result.payment_link;
          this.ex = data.filter.status;
          this.isLoading = false;
        }
      })
      .catch(error => {
        console.log(error);
        this.isLoading = false;
      });
    },

    formatPrice(value) {
      return formatPrice(value);
    },
  },
};
</script>

<style>
</style>